<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'TravelAlerts'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/rac/page-header/travel-alerts-hero-page.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Alerts</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content pt-5">
    <div class="page-content-info">
      <div class="container">
        <h3>Active travel alerts</h3>
        <p>
          <span
            >Worldwide events may impact coverage for new travel insurance
            purchases, or affect your travel plans for policies already
            purchased. Please review all travel alerts related to your area of
            travel, and the important information contained in them.</span
          >
        </p>
        <ul class="link">
          <li>
            <a target="_blank" href="/alfred"
              >Tropical Cyclone Alfred</a
            >
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/covid-faqs"
              >New COVID-19 FAQs</a
            >
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/israel"
              >Israel and the Occupied Palestinian Territories</a
            >
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/israelfaqs"
              >FAQs for Israel and OPT</a
            >
          </li>
        </ul>
        <h3>Inactive travel alerts</h3>
        <p>
          <span
            >The alerts below are alerts that we have published in the past that
            are no longer active.</span
          >
        </p>
        <ul class="link">
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/donot-travel"
              >'Do Not Travel'
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/covid-19"
              >COVID-19
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/old-covid-faqs"
              >COVID-19 FAQs
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/virgin-airline"
              >Virgin Airline
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/china-iran"
              >China and Iran - Do Not Travel
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="/indonesia-volcano"
              >Indonesia’s Mt Agung volcano
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="/hongkong-protest"
              >Hong Kong Protests
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" target="_blank" href="/fiji-cyclone"
              >Fiji's Cyclone Sarai
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
